<template>
  <kbutton
    class="reactiveButton"
    :theme-color="isPrimaryButton ? 'primary' : 'base'"
    :disabled="isLoading || disabled"
    :tabindex="tabindex"
  >
    <span
      v-if="buttonText"
      v-text="!isLoading ? buttonText : ''"
    />
    <slot v-if="!buttonText" />
    <WaitingIndicator
      v-show="isLoading"
      class="indicatorReactiveButton"
    />
  </kbutton>
</template>

<script>
import WaitingIndicator from '~/components/loading-indicators/waiting-indicator.vue'
import {Button} from '@progress/kendo-vue-buttons'

export default {
  components: {
    WaitingIndicator,
    kbutton: Button
  },
  props: {
    isLoading: {
      type: Boolean,
      default() {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false
      }
    },
    buttonText: {
      type: String,
      default() {
        return null
      }
    },
    isPrimaryButton: {
      type: Boolean,
      default() {
        return true
      }
    },
    tabindex: {
      // set to -1 if you want to disable the button from being focused
      type: Number,
      required: false,
      default() {
        return 0
      }
    }
  }
}
</script>

<style lang="scss">
.button__primary {
  .indicatorReactiveButton {
    color: $color__black;
  }
}

.button__secondary {
  .indicatorReactiveButton {
    color: $color__white;
  }
}
</style>
